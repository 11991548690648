import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 32px;

  margin: 0 auto 5em auto;
  padding: 28px 3vw;

  @media screen and (min-width: 2000px) {
    gap: 64px;

    .right-side {
      max-width: 1200px !important;
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  @media screen and (max-width: 960px) {
    .title {
      margin: 20px 0 0 0;
    }
    .filters-showup {
      margin-left: 0px !important;
    }

    .right-side {
      margin-left: 0px !important;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    width: 100%;
    max-width: 1000px;
    /* margin-left: 30px; */
  }

  .title {
    margin-top: 20px;
  }

  .filters-showup {
    width: 100%;

    ul {
      width: 100%;
      overflow-x: auto;
      display: flex;
    }

    li {
      min-width: 70px;

      align-items: center;
      justify-content: space-between;
      padding: 3px 7px;
      background: #213455;
      color: white;
      font-size: 12px;
      margin-right: 10px;
      border-radius: 99px;
    }

    li.clean-all {
      min-width: 70px;
      background: #fce76d;
      color: #213455;
      font-size: 10px;
      display: flex;
      align-items: center;

      span {
        font-size: 15px;
        font-weight: bold;
        transition: color 0.2s;

        &:hover {
          cursor: pointer;
          color: #36bcc1;
        }
      }
    }

    button {
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      font-size: 10px;
      border-radius: 99px;
      font-family: Nunito;
      color: white;
      transition: 0.3s;

      &:hover {
        color: #36bcc1;
      }
    }
  }

  .MuiPagination-root {
    button {
      width: 35px;
      background: #c3c3c3;
      color: white;
      border-radius: 0;

      &:hover {
        background: var(--blueNbikes);
      }

      svg {
        fill: white;
      }
    }
  }

  .MuiPaginationItem-page.Mui-selected {
    background: var(--blueNbikes);
  }
`;

export const AccordionFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const FilterButtons = styled.button`
  width: clamp(50px, 100%, 80px);
  height: clamp(20px, 100%, 25px);

  background-color: #213455;
  border-radius: 80px;

  color: #fff;
  font-size: clamp(10px, 100%, 20px);
  font-weight: bold;
`;

export const Categories = styled.div`
  max-width: 200px;
  width: 100%;
  height: 70%;
  margin-top: 20px;

  @media screen and (max-width: 960px) {
    min-width: 100% !important;
    min-height: 100% !important;
  }
`;

export const Products = styled.div`
  width: 100%;

  margin-bottom: 10px;

  .ListOfProducts {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px;
    background: white;
    padding: 20px;
  }

  .products {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;

    position: relative;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    height: auto;
    width: 100%;

    margin-bottom: 1em;

    #announcements-not-found {
      width: 100%;

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      opacity: 0.3;

      #neutral-icon {
        width: 150px;
        height: 150px;
      }

      #neutral-text {
        font-size: 20px;
        font-weight: bold;

        color: black;

        text-align: center;
      }
    }
  }

  li {
    position: relative;

    display: flex;
    justify-content: center;

    width: 100%;
    max-width: 280px;
    max-height: 300px;

    margin-top: 14px;
    margin-bottom: 14px;

    @media screen and (min-width: 600px) {
      margin-right: 14px;
      margin-left: 14px;
    }

    @media screen and (min-width: 2000px) {
      margin-top: 24px;
      margin-bottom: 24px;

      margin-right: 24px;
      margin-left: 24px;
    }
  }

  @media screen and (max-width: 500px) {
    .products {
      display: flex;
      justify-content: center;
    }
  }

  .pageCounter {
    height: 35px;
    float: right;
    display: flex;
    margin-top: 15px;
    transition: 0.3s;
  }

  .squareCounter {
    width: 35px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #c3c3c3;
    margin: 0 5px;
    transition: 0.2s;
    border: none;

    &:hover {
      cursor: pointer;
      background: #213355;
    }
    &:nth-last-child(1) {
      width: 100px;
    }
  }
`;

export const ProductSquare = styled.div`
  position: relative;

  width: 100%;
  max-width: 280px;
  max-height: 300px;

  transition: 0.5s;
  overflow: hidden;
  border-radius: 10px;
  background: white;

  @media screen and (max-width: 960px) {
    .image {
      width: 100%;
      height: 160px !important;
    }
  }

  .image {
    display: flex;
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
    margin-bottom: 14px;
    justify-content: center;

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      max-width: 290px;
      aspect-ratio: auto;
    }
  }

  .icons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 10px;
    top: 10px;
    transition: 0.5s;
    z-index: ${({ isStarred }) => (isStarred ? '2' : '0')};

    ${({ isStarred }) => {
      if (!isStarred) {
        return css`
          .heart-icon {
            fill: #36bcc1;
          }
        `;
      }
      return css`
        .heart-icon {
          fill: #fce76d;
        }
      `;
    }}

    svg {
      width: 25px;
      height: 25px;
      transition: 0.3s;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1em rgb(0, 0, 0, 0.4);

    p,
    span {
      color: #36bcc1;
    }

    .icons {
      opacity: 1;
      z-index: 2;
    }

    ${({ isStarred }) => {
      if (!isStarred) {
        return css`
          .heart-icon {
            fill: #36bcc1;
          }
        `;
      }
      return css`
        .heart-icon {
          fill: #fce76d;
        }
      `;
    }}
  }

  img {
    width: 240px;
    height: 180px;
  }

  p {
    padding: 6px;
    font-weight: 600;
    color: #213355;
    transition: 0.5s;
  }

  #announcement-title {
    max-width: 280px;

    color: #213355;
    transition: 0.5s;

    font-size: 18px;
    font-weight: 500;

    margin-left: 14px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    padding-left: 14px;

    font-weight: 600;

    color: #213355;

    transition: 0.5s;
  }

  &:hover {
    fill: #334f8d !important;
    cursor: pointer;
    box-shadow: 0 0 1em rgb(0, 0, 0, 0.4);
  }

  .heart-icon:hover {
    fill: #fce76d !important;
    cursor: pointer;
    filter: drop-shadow(0 0 0.2em rgb(0, 0, 0, 0.4));
  }
`;

export const Banner = styled.div`
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 1250px;

  font-size: 25px;

  display: flex;
  justify-content: space-between;
`;

export const SelledTagWrapper = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 2;
  background: white;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 5px 0px 5px 20px ;
  min-width: 150px;
  box-shadow: #6b6b6b 0 2px 2px;
  color: var(--blueNbikes);
`;
export const SelledTagIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blueNbikes);
  svg {
    width: 15px;
    height: 12px;
    margin-right: 5px;
  }
`;
